<script>
export default {
  name: "HomeHero",
  data() {
    return {
      selectValue: 'Individual & Family'
    }
  },
  methods: {
    goToPath() {
      const urlParams = new URLSearchParams(location.search)
      const params = '?' + urlParams.toString()

      if (this.selectValue === 'Individual & Family') {
        window.location.href = process.env.VUE_APP_HEALTH_PATH + params
      }

      if (this.selectValue === 'Medicare') {
        window.location.href = process.env.VUE_APP_MEDICARE_PATH + params
      }

      if (this.selectValue === 'Affordable care act') {
        window.location.href = process.env.VUE_APP_AFFORDABLE_CARE_ACT_PATH + params
      }
    }
  }
}
</script>

<template>
  <div class="hero-section">
    <div class="bg-overlay"></div>
    <div class="content">
      <h1 class="header">Helping You Find Affordable Health Insurance</h1>
      <p class="text">Get the coverage you need now.</p>
      <div class="select-container">
        <select v-model="selectValue">
          <option value="Individual & Family">Individual & Family</option>
          <option value="Medicare">Medicare</option>
          <option value="Affordable care act">Affordable care act</option>
        </select>
        <button @click="goToPath">Get Quotes</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .hero-section {
    margin-top: 76px;
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 72.5px 0 150.5px 0;
    text-align: center;
    height: auto;
    background-image: url("../../assets/img/hero-img-family.webp");
    background-size: cover;
    background-position: 100% 50%;
    background-attachment: initial;
    .bg-overlay {
      position: absolute;
      opacity: 0.57;
      background-color: rgba(48,48,48,1);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
    .content {
      position: inherit;
      z-index: 20;
      color: rgba(255, 255, 255, 1);
      padding: 0 20px;
      .header {
        margin: 32px auto 8px auto;
        font-weight: 700;
        font-style: normal;
        font-size: 44px;
        max-width: 960px;
        color: white;
      }
      .text {
        margin: 18px auto 8px auto;
        max-width: 460px;
        font-size: 30px;
        font-weight: 300;
      }
      .select-container {
        background-color: #e8e8e8;
        margin: 40px auto 0 auto;
        max-width: 496px;
        max-height: 79px;
        width: 100%;
        height: 100%;
        padding: 8px;
        box-shadow: 5px 5px 18px 1px rgb(51 51 51);
        display: flex;
        select {
          margin: 0;
          width: 65%;
          height: 63px;
          border: none;
          padding: 0 20px;
          font-size: 25px;
          appearance: none;
          &:focus {
            outline: none;
          }
        }
        button {
          width: 35%;
          height: 63px;
          background-color: rgba(226,40,102,1);
          border: 0;
          color: white;
          cursor: pointer;
          font-size: 25px;
        }
      }
    }
  }
  @media (max-width: 755px) {
    .hero-section {
      margin-top: 68px;
    }
  }

  @media (max-width: 520px) {
    .hero-section {
      margin-top: 61px;
      padding: 70px 0 90px 0;
      .content {
        .header {
          font-size: 31px;
        }
        .text {
          font-size: 24px;
        }
        .select-container {
          flex-direction: column;
          max-height: max-content;
          select {
            width: 100%;
          }
          button {
            width: 100%;
          }
        }
      }
    }
  }
</style>