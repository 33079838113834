<script>
import HomeNav from '@/components/Home/nav/HomeNav'
import { init } from '@/paths/helpers'
import HomeHero from '@/components/Home/HomeHero'
import CompaniesBanner from '@/components/Home/CompaniesBanner'
import Services from '@/components/Home/Services'
import WhatMattersMost from '@/components/Home/WhatMattersMost'
import HomeFAQ from '@/components/Home/HomeFAQ'
import RealHelp from '@/components/Home/RealHelp'
import Testimonials from '@/components/Home/Testimonials'
import Articles from '@/components/Home/Articles'
import GetQuoteSection from '@/components/Home/GetQuoteSection'
import HomeFooter from '@/components/Home/HomeFooter'

export default {
  name: 'App',
  components: {
    HomeNav,
    HomeHero,
    CompaniesBanner,
    Services,
    WhatMattersMost,
    HomeFAQ,
    RealHelp,
    Testimonials,
    Articles,
    GetQuoteSection,
    HomeFooter
  },
  created() {
    //TODO: Need pushnami key
    function addPushnami() {
      let pushnamiKey = '6140e3faefd8f0001251c9a9';
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = 'https://api.pushnami.com/scripts/v1/pushnami-adv/' + pushnamiKey;
      script.onload = function() {
        window.Pushnami
          .update()
          .prompt();
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    addPushnami()
    init(this)
  }
}
</script>

<template>
  <div>
    <home-nav/>
    <home-hero/>
    <companies-banner/>
    <services/>
    <what-matters-most/>
    <home-f-a-q/>
    <real-help/>
    <testimonials/>
    <articles/>
    <get-quote-section/>
    <home-footer/>
  </div>
</template>

<style lang="scss">
@import "./../../scss/style";
</style>