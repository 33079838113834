import Vue from 'vue'
import App from './HomeApp'
import store from '@/store'
import Buefy from 'buefy'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { setRetriever } from '@/paths/helpers'
import '@/scss/style.scss'

Vue.use(Buefy)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  store,
  render: h => h(App)
})

//TODO: Need retreaver campaign key
setRetriever("3b60d24e10ecd87e63eeb89d78bc622d")