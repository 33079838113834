<script>
export default {
  name: "HomeFooter",
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<template>
  <section class="footer-section">
    <div class="container">
      <div class="footer-nav">
        <div class="footer-box logo-box">
          <img src="../../assets/img/American-First-Health---Logo-Design---final---high-resolution---1-320w.png" alt="">
        </div>
        <div class="footer-box">
          <h4>Links</h4>
          <a href="/health">Get Health Insurance</a>
          <a href="/medicare">Get Medicare</a>
        </div>
        <div class="footer-box">
          <h4>Company</h4>
          <a href="/privacy-policy" target="_blank">Privacy Policy</a>
          <a href="/privacy-policy#ca-privacy-rights" target="_blank">CA Privacy Policy</a>
          <a href="/terms-of-service" target="_blank">Terms of Use</a>
        </div>
        <div class="footer-box">
          <h4>Contact Us</h4>
          <a href="mailto: info@americanfirsthealth.com">Info@americanfirsthealth.com</a>
          <p>© {{ currentYear }} AmericanFirstHealth.com</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .footer-section {
    padding: 70px 0;
    .container {
      margin: auto;
      max-width: 960px;
      padding: 0 10px;
      .footer-nav {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .footer-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          max-width: 240px;

          img {
            width: 100%;
            height: 100%;
            max-width: 180px;
            margin-right: 10px;
          }

          .footer-logo {
            background-image: url("../../assets/img/American-First-Health---Logo-Design---final---high-resolution---1-320w.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            min-height: 100px;
            height: 100%;
            width: 100%;
          }

          h4 {
            font-size: 15px;
            color: rgba(90, 90, 90, 1);
            font-weight: bold;
            margin-bottom: 10px;
          }

          a {
            text-decoration: none;
            color: rgba(65, 67, 69, 1);
            line-height: 1.5;
            font-size: 14px;
          }

          p {
            font-size: 14px;
            color: rgba(65, 67, 69, 1);
            line-height: 1.5;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .footer-section .container .footer-nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-self: flex-start;
      gap: 20px;
      justify-items: center;

      .footer-box.logo-box {
        align-self: center;
      }

      .footer-box {
        img {
          max-width: unset;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    .footer-section .container .footer-nav {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>